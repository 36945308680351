import { formatGraphQlDate } from '../../helpers/date';
import { companyFilter } from '../../apollo/reactive-variables/filters';
import dayjs from '../../config/dayjs';

const selectedCompanyFilter = (
  selectedCompany,
  setCustomVariables = () => {},
  defaultFilterDays = 14,
) => {
  const filters = selectedCompany
    ? {
      companyId: selectedCompany.id,
      dateIssued_Gte: null,
      dateIssued_Lte: null,
    }
    : {
      companyId: null,
      dateIssued_Gte: formatGraphQlDate(
        dayjs().subtract(defaultFilterDays, 'days'),
      ),
      dateIssued_Lte: formatGraphQlDate(dayjs()),
    };
  setCustomVariables((prev) => ({ ...prev, ...filters }));
  companyFilter({
    selectedCompany,
    filters,
  });
};

export default selectedCompanyFilter;

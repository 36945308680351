import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const NoRatificationActionsFilter = ({ filter, setFilter }) => (
  <Tooltip title="Sin gestión">
    <IconButton onClick={() => setFilter(!filter)}>
      <FormatListBulletedIcon color={filter ? 'primary' : 'text.primary'} />
    </IconButton>
  </Tooltip>
);

NoRatificationActionsFilter.propTypes = {
  filter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default NoRatificationActionsFilter;

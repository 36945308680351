import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useLazyQuery } from '@apollo/client';
import { GET_SIMPLE_MASTER_ENTITIES } from '../../graphql';
import { useDebounce, useGetCountryFromUrl } from '../../hooks';
import { CompanyType } from '../../propTypes';

const CompanyFilter = ({
  selectedCompany,
  setSelectedCompany,
  companyFilterSetProps,
  inputLabel,
  ...props
}) => {
  const country = useGetCountryFromUrl();

  const [fetchCompanies, { data, loading }] = useLazyQuery(
    GET_SIMPLE_MASTER_ENTITIES,
  );

  const debouncedFetch = useDebounce((value) => {
    fetchCompanies({
      variables: {
        globalFilter: value,
        first: 10,
        countryId: country?.id,
        ...companyFilterSetProps,
      },
    });
  }, 500);

  const handleInputChange = (_, newInputValue) => {
    if (!newInputValue.trim()) return;
    debouncedFetch(newInputValue);
  };

  const options = useMemo(
    () => data?.getMasterEntities.edges.map((i) => i.node) || [],
    [data],
  );

  const getOptionLabel = useCallback(
    (option) => `${option?.displayNationalIdentifier} ${option?.name}`,
    [],
  );

  const handleChange = useCallback(
    (_, value) => {
      setSelectedCompany(value);
    },
    [setSelectedCompany],
  );

  const isOptionEqualToValue = useCallback(
    (option, value) => option.id === value.id,
    [],
  );

  return (
    <Autocomplete
      id="select-company"
      loading={loading}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      value={selectedCompany}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={handleInputChange}
      filterOptions={(x) => x}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
      {...props}
    />
  );
};

CompanyFilter.propTypes = {
  selectedCompany: CompanyType,
  setSelectedCompany: PropTypes.func.isRequired,
  companyFilterSetProps: PropTypes.shape(),
  inputLabel: PropTypes.string,
};

CompanyFilter.defaultProps = {
  selectedCompany: null,
  inputLabel: 'Selecciona deudor',
  companyFilterSetProps: {},
};

export default CompanyFilter;

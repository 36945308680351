/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Search from '@mui/icons-material/Search';
import { backendFiltersTransformation, parseClip } from '../../helpers';
import { DownloadExcelIcon } from '../icons';
import { HtmlTooltip } from '../tooltips';
import { useIsMobile } from '../../hooks';

const FingoTextField = ({
  canPasteExcel,
  setColumnFilter,
  onChangeExcel,
  onChange,
  placeholder,
  ...props
}) => {
  const isMobile = useIsMobile();
  const [isExcel, setIsExcel] = useState(false);
  const [excelValue, setExcelValue] = useState(null);
  const handleOnChange = (e) => {
    if (isExcel) {
      setIsExcel(false);
      setExcelValue(null);
      setColumnFilter({});
    }
    onChangeExcel(undefined);
    onChange(e);
  };
  const handlePaste = (e) => {
    const result = parseClip(e.clipboardData.getData('text'), true);
    if (result) {
      setIsExcel(true);
      onChangeExcel(result);
      setExcelValue(result);
      onChange({ target: { value: '' } });
      document.activeElement.blur();
      setColumnFilter(
        backendFiltersTransformation({
          columnField: 'company_MasterEntity_Rut',
          value: result,
          operatorValue: 'isAnyOf',
        }),
      );
      e.preventDefault();
    }
  };

  const innerProps = canPasteExcel
    ? {
      onPaste: handlePaste,
      InputProps: {
        endAdornment: !isMobile && (
          <HtmlTooltip
            title={(
              <>
                <Typography color="inherit">Pro-Tip</Typography>
                Pega una columna desde <b>excel</b> para poder filtrar
                múltiples valores desde el campo <em>rut emisor</em>.
              </>
                )}
          >
            <InputAdornment position="end">
              {isExcel ? <DownloadExcelIcon /> : <Search />}
            </InputAdornment>
          </HtmlTooltip>
        ),
        disableUnderline: true,
      },
    }
    : {
      InputProps: {
        endAdornment: !isMobile && (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
        disableUnderline: true,
      },
    };

  return (
    <TextField
      name="search"
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onChange={handleOnChange}
      variant="filled"
      fullWidth
      sx={(theme) => ({
        '& input': {
          ...theme.typography.formLabel,
        },
      })}
      label={isExcel ? excelValue : placeholder}
      {...innerProps}
      {...props}
    />
  );
};

FingoTextField.propTypes = {
  canPasteExcel: PropTypes.bool,
  setColumnFilter: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onChangeExcel: PropTypes.func,
};

FingoTextField.defaultProps = {
  canPasteExcel: false,
  setColumnFilter: () => {},
  onChange: () => {},
  onChangeExcel: () => {},
  placeholder: 'Buscar',
};

export default FingoTextField;
